import api from "@/api/axios_service.js";

export async function userRoles(filtros) {
  const response = await api.get(
    `/graficos/usuarios-roles${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getCadastrados() {
  const response = await api.get("/graficos/usuarios-cadastrados");
  return response.data.result;
}

export async function getCadastradosPorDia() {
  const response = await api.get("/graficos/usuarios-cadastrados-dia");
  return response.data.result;
}

export async function getEduzzSalesPorDia() {
  const response = await api.get("/graficos/eduzz-sales-dia");
  return response.data.result;
}

export async function getCheckinsPorDia() {
  const response = await api.get("/graficos/checkins-dia");
  return response.data.result;
}

export async function userCheckin(filtros) {
  const response = await api.get(
    `/graficos/usuarios-checkin${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function vacinaCovid(filtros) {
  const response = await api.get(
    `/graficos/usuarios-vacinados${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function empreendedores() {
  const response = await api.get("/graficos/usuarios-empreendedores");
  return response.data.result;
}

export async function topDispositivos() {
  const response = await api.get("/graficos/usuarios-dispositivos");
  return response.data.result;
}

export async function usuariosGenero() {
  const response = await api.get("/graficos/usuarios-genero");
  return response.data.result;
}

export async function gruposRenda() {
  const response = await api.get("/graficos/grupos-renda");
  return response.data.result;
}

export async function noShow() {
  const response = await api.get("/graficos/no-show");
  return response.data.result;
}

export async function show() {
  const response = await api.get("/graficos/show");
  return response.data.result;
}
